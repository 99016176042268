<template>
    <div>
        <b-sidebar id="sidebar-editrole" sidebar-class="sidebar-lg" bg-variant="white" shadow backdrop no-header right>
            <template #default="{ hide }">
                <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
                    <h3 class="mb-0">
                        Edit Role :
                    </h3>
                    <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
                </div>

                <validation-observer ref="editUserRules">
                    <b-form @submit="onSubmit" @reset="onReset" class="m-2" id="FORMID">
                        <b-form-group id="display_name" label="Display Name:" label-for="diplay_name">
                            <validation-provider #default="{ errors }" name="display_name" rules="required">
                                <b-form-input id="display_name" v-model="form.display_name" @input="onChangeName()"
                                    placeholder="Display Name">
                                </b-form-input>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>

                        </b-form-group>
                        <b-form-group id="name" label="Name:" label-for="input-name">
                            <validation-provider #default="{ errors }" name="name" rules="required">
                                <b-form-input style="pointer-events: none;" id="input-name" v-model="form.name" placeholder="Enter Name">
                                </b-form-input>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                        <!-- {{ form.selected_permission }} -->
                        <!-- <b-form-group id="permission" label-for="input-role">
                            <b-form-radio-group v-model="form.selected_permission" :options="permission_options" class="demo-inline-spacing"
                                name="radio-inline" />
                        </b-form-group> -->
                        <b-form-group id="permission" label="Permissions:" label-for="input-role">
                            <b-form-checkbox-group v-model="form.selected_permission" :options="permission_options"
                                class="demo-inline-spacing" name="checkbox-inline" />
                        </b-form-group>
                    </b-form>
                </validation-observer>
            </template>

            <template #footer>
                <div class="flex mb-2 ml-2">
                    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" form="FORMID" variant="success" class="mr-2"
                        type="submit" @click.prevent="onSubmit">
                        Update
                    </b-button>

                    <b-button type="reset" variant="danger" class="mr-2" form="FORMID">
                        Reset
                    </b-button>

                    <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="outline-danger" form="FORMID"
                        @click="closeSidebar">
                        Cancel
                    </b-button>
                </div>
            </template>
        </b-sidebar>
    </div>
</template>

<script>
import {
    BSidebar, BForm, BFormGroup, BFormInput, BFormTextarea, BButton, BFormRadioGroup, BFormCheckboxGroup
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
    required, name, display_name,
} from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
    components: {
        BForm, BFormGroup, BFormInput, BFormTextarea, BButton, BSidebar, BFormRadioGroup,
        ValidationProvider, ValidationObserver, ToastificationContent, BFormCheckboxGroup
    },
    directives: {
        Ripple,
    },
    props: {
        editRole: {
            type: Object,
            required: true,
        }
    },
    watch: {
        editRole() {
            // console.log('this.edit props', this.editRole)
            this.form.id = this.editRole.id;

            this.form.name = this.editRole.name;
            this.form.display_name = this.editRole.display_name;

            this.form.selected_permission = this.editRole.permissions.map((p) => p.id)

            // this.form.selected_permission.push(this.editRole.permissions[0]['id']);

        }
    },
    data() {
        return {
            required, name, display_name,
            form: {
                id: '',
                name: '',
                display_name: '',
            },
            selected_permission: [],
            permission_options: [
            ],
        }
    },
    created() {
        this.getAllPermissions();
    },
    mounted() {
        // // fetch data from API
        // axios.get('/api/roles/1')
        //     .then(response => {
        //         // assign response data to form object
        //         this.form = response.data;
        //     })
        //     .catch(error => {
        //         console.log(error);
        //     });
    },

    methods: {
        slugify(str) {
            str = str.replace(/^\s+|\s+$/g, ''); // trim
            str = str.toLowerCase();

            // remove accents, swap ñ for n, etc
            var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
            var to = "aaaaeeeeiiiioooouuuunc------";
            for (var i = 0, l = from.length; i < l; i++) {
                str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
            }

            str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
                .replace(/\s+/g, '_') // collapse whitespace and replace by -
                .replace(/-+/g, '_'); // collapse dashes

            return str;
        },
        onChangeName() {
            this.form.name = this.slugify(this.form.display_name)
            console.log(this.slugify(this.form.display_name))
        },
        // submitForm() {
        //     console.log(this.form);
        // },
        onReset(event) {
            event.preventDefault()
            this.clearForm()
        },
        getAllPermissions() {
            this.loader = true;
            axios
                .get(process.env.VUE_APP_API + "/permissions")
                .then((res) => {
                    this.loader = false;
                    this.permission_options = res["data"].map((option) => ({
                        text: option.name, // Display name for the radio button
                        value: option.id, // Value for the radio button
                    }));
                })
                .catch((error) => {
                    this.loader = false;
                    console.log(error);
                });
        },
        clearForm() {
            this.form.name = ''
            this.form.display_name = ''
            this.form.selected_permission = []
        },
        onSubmit(event) {
            this.$refs.editUserRules.validate().then(success => {
                console.log('this.form data', this.form)
                if (success) {
                    axios.post(process.env.VUE_APP_API + '/update', this.form)
                        .then(res => {
                            if (res.data.success) {
                                this.$toast
                                    ({
                                        component: ToastificationContent,
                                        props: {
                                            title: res.data.message,
                                            icon: 'AlertOctagonIcon',
                                            text: '',
                                            variant: 'success',
                                        },
                                    },
                                        {
                                            position: 'top-right'
                                        })
                                this.$emit('refresh')
                                this.closeSidebar()
                            }
                            else {
                                this.$toast
                                    ({
                                        component: ToastificationContent,
                                        props: {
                                            title: 'Failed',
                                            icon: 'AlertOctagonIcon',
                                            text: res.data.success,
                                            variant: 'danger',
                                        },
                                    },
                                        {
                                            position: 'top-left'
                                        })
                            }
                        })
                        .catch(error => {
                            console.log(error);
                        });
                }
            })
        },
        closeSidebar() {
            this.$root.$emit('bv::toggle::collapse', 'sidebar-editrole')
            this.clearForm()
        },
    },
    computed: {
    }

}
</script>
